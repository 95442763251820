import Vue from "vue";
import VueRouter from "vue-router";
import Home from './views/index';
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    redirect: '/index'
  },
  {
    path: "/index",
    name: "index",
    component: Home,
  },
];
const router = new VueRouter({
  base: process.env.VUE_APP_BASE_URL,
  mode: "hash",
  routes,
});

export default router;
